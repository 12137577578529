import React, { useMemo } from 'react'
import { Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { useIsSSR } from 'utils/isSSR'

import { Widget } from './styles';
import ContentBox from './ContentBox';

const S = {};
S.Grid = styled.div`
  > * {
    margin-top: 2rem;
    
    &:first-child {
      margin-top: 0;
    }
  }
`;

const Grid = ({
  node,
}) => {
  const isSSR = useIsSSR()

  const rows = useMemo(() => {
    return (node?.rows ?? [])
      .filter(r => !r.hidden)
      .map((row) => {
        return {
          ...row,
          columns: row.columns.filter(c => !c.hidden)
        }
      })
      .filter(r => r.columns.length > 0)
  }, [node])

  if (!rows.length || isSSR) {
    return null;
  }

  return (
    <Widget>
      <S.Grid>
        {
          rows.map((row) => (
            <Row key={`row-${row._key}`} align={row.align} justify={row.justify}>
              {
                row.columns.map((column) => (
                  <Col {...column.breakpoints} key={`row-${row._key}-col-${column._key}`}>
                    <ContentBox blocks={column.content} />
                  </Col>
                ))
              }
            </Row>
          ))
        }
      </S.Grid>
    </Widget>
  )
};

export default Grid;
